import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { Hero } from "../components/hero"
import PageCTA from "../components/pageCTA"
import SideCTA from "../components/sideCTA"
import Card from "../components/card"

export const query = graphql`
  query PageWithPageCtaQuery($id: String) {
    contentfulPageWithPageCta(id: {eq: $id}) {
      id
      title
      description
      bannerImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      header
      pageContent {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 250)
            description
          }
          ... on ContentfulCardCollection {
            contentful_id
            __typename
            cardCollection {
              references {
                id
                heading
                image {
                  gatsbyImageData (
                    aspectRatio: 1
                  )
                }
                imageAltText
                buttonText
                destination
                cardContent {
                  raw
                }
              }
            }
          }
          ... on ContentfulJobListings {
            contentful_id
            __typename
            id
            jobs {
              references {
                jobTitle
                permalink
                jobType
              }
            }
          }
          ... on ContentfulSideCallToAction {
            contentful_id
            __typename
            title
            image {
              gatsbyImageData
            }
            imageAltText
            cardContent {
              raw
            }
            buttonText
            destination
          }
        }
      }
      pageCta {
        header
        buttonText
        destination
        content {
          raw
        }
      }
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-lg sm:text-xl text-gray-500 leading-relaxed">{children}</p>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 className="text-3xl">{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 className="text-2xl">{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4 className="text-xl">{children}</h4>,
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list-inside list-disc text-gray-500 text-lg ml-4">{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol  className="list-inside list-decimal text-gray-500 text-lg space-y-2">{children}</ol>,
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.includes("http")) {
        return (
          <a href={node.data.uri}>{children}</a>
        )
      } else {
        return (
          <Link to={node.data.uri}>{children}</Link>
        )
      }
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      const transformedChildren = documentToReactComponents(node, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => children,
          //[BLOCKS.UL_LIST]: (node, children) => children,
          [BLOCKS.LIST_ITEM]: (node, children) => children,
          [INLINES.HYPERLINK]: (node, children) => {
            if (node.data.uri.includes("http")) {
              return (
                <a href={node.data.uri}>{children}</a>
                )
            } else {
              return (
                <Link to={node.data.uri}>{children}</Link>
              )
            }
          },
        },
      });
      return <li>{transformedChildren}</li>;
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { gatsbyImageData, description } = node.data.target
      if (!gatsbyImageData) {
        return null
      }
      return (
        <GatsbyImage
          image={gatsbyImageData}
          alt={description}
          className="float-none md:float-right m-4"
        />
      )
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const { image, imageAltText, buttonText, destination, cardContent } = node.data.target
      if (node.data.target.__typename === "ContentfulSideCallToAction") {
        return (
          <SideCTA image={image.gatsbyImageData} alt={imageAltText} buttonText={buttonText} destination={destination} >
            {renderRichText(cardContent, options)}
          </SideCTA>
        )
      }
      return null
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (node.data.target.__typename === "ContentfulJobListings") {
        return (
          <ul className="list-inside list-disc text-gray-500 text-lg ml-4">
            {node.data.target.jobs.references.map(job => (
              <li key={job.jobTitle}>
                <Link to={job.permalink}>
                  {`${job.jobTitle} - ${job.jobType}`}
                </Link>
              </li>
            ))}
          </ul>
        )
      } else if (node.data.target.__typename === "ContentfulSideCallToAction") {
        const { image, imageAltText, buttonText, destination, cardContent } = node.data.target
        return (
          <div className="float-right">
            <SideCTA image={image.gatsbyImageData} alt={imageAltText} buttonText={buttonText} destination={destination} >
              {renderRichText(cardContent, options)}
            </SideCTA>
          </div>
        )
      } else if (node.data.target.__typename === "ContentfulCardCollection") {
        const _options = {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
              <p className="font-serif text-gray-500 text-lg">{children}</p>
            ),
            [BLOCKS.LIST_ITEM]: node => <li>{node.content[0].content[0].value}</li>,
          },
        }
        return (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {node.data.target.cardCollection.references.map((card) => (
              <Card
                key={card.id}
                h2={card.heading}
                image={card.image.gatsbyImageData}
                alt={card.imageAltText}
                buttonText={card.buttonText}
                destination={card.destination}
              >
                {renderRichText(card.cardContent, _options)}
              </Card>
            ))}
          </div>
        )
      }
      return null
    }
  },
}
const optionsPageCta = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="font-serif text-gray-500 text-xl">{children}</p>,
    [INLINES.HYPERLINK]: (node, children) => <Link to={node.data.uri} className="text-purple-500 hover:text-blue-700 hover:underline">{children}</Link>
  },
}

const PageWithPageCta = ({ data }) => {
  const banner = data.contentfulPageWithPageCta?.bannerImage?.gatsbyImageData
  const { title, description, header, pageContent, pageCta } = data?.contentfulPageWithPageCta

  return (
    <Layout>
      <SEOComponent title={title} description={description} />
      <Hero image={banner} header={header} />
      <section className="container my-4 mx-auto pt-4 sm:px-24 px-4 pb-6">
        <div className="font-serif space-y-4">
          {renderRichText(pageContent, options)}
        </div>
      </section>
      {pageCta && (
        <PageCTA
          h2={pageCta.header}
          buttonText={pageCta.buttonText}
          destination={pageCta.destination}
        >
          {renderRichText(pageCta.content, optionsPageCta)}
        </PageCTA>
      )}
    </Layout>
  )
}

export default PageWithPageCta
