import * as React from "react"
import { navigate } from "gatsby-link"
import { GatsbyImage } from "gatsby-plugin-image"

const SideCTA = ({ h3, image, alt, children, buttonText, destination }) => {
  return (
    <div className="font-sans p-5 space-y-4">
      {h3 && (
        <h3 className="text-lg md:text-xl lg:text-2xl">{h3}</h3>
      )}
      {image && (
        <GatsbyImage image={image} alt={alt}/>
      )}
      {children}
      <button
        className="bg-purple-500 py-2 px-6 rounded-full text-white hover:text-gray-700 uppercase"
        onClick={() => navigate(destination)}
      >
        {buttonText}
      </button>
    </div>
  )
}

export default SideCTA